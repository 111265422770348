@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import the custom font */
@font-face {
  font-family: 'TASAOrbiterDeck';
  src: url('../public/fonts/tsa/TASAOrbiterDeck-Regular.otf') format('opentype');
  font-weight: 900;
  /* Bold weight */
  font-style: bold-regular;
}


body {
  font-family: 'TASAOrbiterDeck', Arial, sans-serif;
  background-color: #ffffff;
  /* Default light mode background */
  color: #111827;
  /* Default text color */
}

/* Dark mode adjustments */
.dark body {
  background-color: #0f172a;
  /* Deep green/blue tone */
  color: #e5e7eb;
  /* Light text */
}

/* Gradient animation property */
@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

/* Gradient border styles */
.gradient-border-button {
  --bg-color: linear-gradient(white, white);
  background: padding-box var(--bg-color), border-box var(--border-color);
}

.gradient-border-card {
  --bg-color: linear-gradient(white, white);
  background: padding-box var(--bg-color), border-box var(--border-color);
}

/* In dark mode, adapt gradient borders */
.dark .gradient-border-button,
.dark .gradient-border-card {
  --bg-color: linear-gradient(#1f2937, #1f2937);
}

/* Firefox support */
@-moz-document url-prefix() {

  .gradient-border-button,
  .gradient-border-card {
    border: 2px solid transparent;
    background-clip: padding-box;
  }
}

/* Fallback for unsupported browsers */
@supports not (background: paint(something)) {

  .gradient-border-button,
  .gradient-border-card {
    position: relative;
    border: 2px solid #3b82f6;
  }

  .dark .gradient-border-button,
  .dark .gradient-border-card {
    border: 2px solid #1d4ed8;
  }
}